import React from "react";

const Header = () => {
  return (
    <nav className="flex items-end py-8 h-48 text-white text-5xl bg-green-500">
      <div className="w-full max-w-7xl mx-auto">
        <div className="flex items-center mx-4">
          <img src="/static/logo-white.png" className="mr-4 lg:mr-8" width="70px" alt="logo" />
          <div className="mochi-pop-one">Rupira</div>
        </div>
      </div>
    </nav>
  )
}

export default Header;