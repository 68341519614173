import React from "react";

const Footer = () => {
  return (
    <footer className="flex my-8 pt-8 border-t border-gray-300">
      <div className="mx-auto text-center">
        <div className="flex my-4 justify-center">
          <img width="40px" className="rounded-lg" src="/static/logo.jpg" alt="logo" />
          <div className="mochi-pop-one text-2xl text-green-500 ml-2">Rupira</div>
        </div>
        <ul className="flex text-gray-500">
          <li className="p-4 whitespace-nowrap">
            <a href="/">Terms of use</a>
          </li>
          <li className="p-4 whitespace-nowrap">
            <a href="/privacy">Privacy Policy</a>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer;