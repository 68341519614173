import * as React from "react"

const Sidebar = () => {
  return (
    <div className="mx-4 mt-8 lg:mt-0 lg:mx-0 py-2 pr-4 lg:border-r lg:border-gray-200 sticky top-0">
      <div className="pr-4 py-4 bg-gray-200 rounded-lg ">
        <p className="text-xs px-2 text-gray-500">Navigation</p>
        <ul className="list-none text-gray-600">
          <li className="p-2 whitespace-nowrap">
            <a href="/">Terms of use</a>
          </li>
          <li className="p-2 whitespace-nowrap">
            <a href="/privacy">Privacy Policy</a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Sidebar;