import * as React from "react"
import Sidebar from "../components/sidebar";
import Footer from "../components/footer";
import Header from "../components/header";

// markup
const IndexPage = () => {
  return (
    <main>
      <title>Terms of Use</title>
      <Header />
      <div className="w-full max-w-7xl mx-auto mt-6">
        <div className="flex flex-col-reverse lg:flex-row items-start">
          <Sidebar />
          <div className="mx-4 lg:mx-8 text-gray-700 text-justify">
            <h2 className="text-4xl py-6 text-gray-600">Terms of Use</h2>
            <p>Halaman ini berisi informasi syarat dan ketentuan dalam penggunaan aplikasi <span className="text-green-600">Hi Rupira</span></p>
            <div className="text-justify">
              <h3 className="text-blue-400 text-xl py-4 lg:text-3xl lg:py-6" id="who-use-service">1. Siapa yang Menggunakan Layanan ini</h3>
              <div className="border-blue-400 border-l-2 pl-4 ml-2 lg:border-l-4 lg:pl-6">
                <p>
                  Anda dapat menggunakan Layanan jika Anda telah menyetujui persyaratan dengan Hi Rupira. Selain itu Anda
                  juga harus berusia minimal 13 tahun untuk dapat menggunakan Layanan ini. Jika anda menerima persyaratan
                  menggunakan Layanan ini atas nama perusahaan, organisasi, pemerintahan atau entitas legal lainnya, Anda
                  menyatakan bahwa anda berwenang untuk melakukannya dan memiliki wewenang untuk mengikat entitas tersebut
                  ke dalam persyaratan ini.
                </p>
              </div>

              <h3 className="text-green-400 text-xl py-4 lg:text-3xl lg:py-6" id="privacy">2. Privasi</h3>
              <div className="border-green-400 border-l-2 pl-4 ml-2 lg:border-l-4 lg:pl-6">
                <p>
                  Detail kebijakan privasi kami dapat anda lihat pada halaman
                </p>
                <br />
                <p className="text-green-500">
                  <a href="https://terms.rupira.com/privacy/">https://terms.rupira.com/privacy/</a>
                </p>
              </div>
              <h3 className="text-red-400 text-xl py-4 lg:text-3xl lg:py-6" id="service-content">3. Konten pada Layanan</h3>
              <div className="border-red-400 border-l-2 pl-4 ml-2 lg:border-l-4 lg:pl-6">
                <p>
                  Anda bertanggung jawab terhadap diri anda dalam menggunakan Layanan dan setiap konten yang Anda bagikan.
                  Anda harus menyediakan menyediakan konten yang baik untuk dibagikan dengan  orang lain.
                </p>

                <br />

                <p>
                  Setiap konten yang anda bagikan melalui Layanan ini atau yang Anda dapatkan melalui Layanan ini adalah
                  resiko Anda sendiri. Kami tidak dapat menjamin kelengkapan, kebenaran dan  kepercayaan dari setiap konten
                  yang dibagikan oleh Layanan kami. Anda mengerti bahwa Anda tidak diperbolehkan untuk membagikan konten
                  yang menyinggung, tidak akurat, melanggar hak cipta atau tidak pantas dan Kami memiliki hak untuk
                  menghapus konten yang melanggar perjanjian pengguna.
                </p>

                <br />

                <p>
                  Dengan memposting atau menampilkan konten pada Layanan Kami, Anda memberikan kami lisensi bebas royalti
                  untuk menggunakan, menyalin, memproses, memodifikasi, mempublikasikan, menampilkan dan mendistribusikan
                  konten pada setiap media. Lisensi ini mengizinkan kami untuk membuat konten Anda tersedia di seluruh
                  dunia dan membiarkan orang lain untuk  melakukan hal yang
                  sama.
                </p>

                <br />

                <p>
                  Anda harus menjamin bahwa anda memiliki atau memperoleh semua hak, lisensi, persetujuan, izin dan
                  otoritas dari tiap konten yang anda kirimkan. Anda juga setuju bahwa setiap konten yang anda bagikan
                  tidak mengandung materi yang tunduk pada hak cipta dan hak kepemilikan lainnya, kecuali jika anda telah
                  memiliki izin untuk memposting materi dan memberikan Hi Rupira lisensi yang dijelaskan di atas.
                </p>
              </div>
              <h3 className="text-purple-400 text-xl py-4 lg:text-3xl lg:py-6" id="terms">4. Penggunaan Layanan</h3>
              <div className="border-purple-400 border-l-2 pl-4 ml-2 lg:border-l-4 lg:pl-6">
                <p>
                  Hi Rupira memberikan Anda akses untuk mengakses dan menggunakan layanan, Anda mengizinkan Kami untuk
                  menampilkan konten atau informasi dari Layanan baik yang dikirimkan oleh Anda atau orang lain. Anda juga
                  setuju untuk tidak menyalahgunakan Layanan Kami contohnya seperti
                </p>

                <br />

                <ul className="ml-8 list-disc">
                  <li>Melakukan spam, mengirim virus ke dalam Layanan kami.</li>
                  <li>Mengakses Layanan kami dengan cara apapun selain melalui antarmuka yang telah Kami sediakan saat ini.</li>
                  <li>Menyelidiki, menguji kerentanan sistem atau jaringan yang melanggar keamanan atau otentikasi.</li>
                </ul>

                <br />

                <p>
                  Anda bertanggung jawab dalam mengamankan akun anda. Sehingga Anda harus menggunakan password yang kuat
                  dan membatasi orang yang dapat menggunakan akun Anda. Kami tidak akan bertanggung jawab atas kerusakan
                  dan kerugian yang disebabkan oleh kegagalan Anda dalam mematuhi hal-hal diatas. Selain itu kami memiliki
                  hak untuk mensuspend, menonaktifkan akun Anda atau berhenti memberikan anda semua atau sebagian Layanan
                  karena alasan apapun atau tanpa alasan.
                </p>
              </div>
            </div>
          </div>
          <div className="lg:sticky top-0 mx-4 lg:ml-8 py-2 lg:py-4 text-gray-700 lg:min-w-max">
            <p className="p-2 font-semibold text-lg">Table of Contents</p>
            <ul className="text-sm list-none text-gray-600">
              <li className="p-2">
                <a href="#who-use-service">Siapa yang Menggunakan Layanan ini</a>
              </li>
              <li className="p-2">
                <a href="#privacy">Privasi</a>
              </li>
              <li className="p-2">
                <a href="#service-content">Konten pada Layanan</a>
              </li>
              <li className="p-2">
                <a href="#terms">Penggunaan Layanan</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  )
}

export default IndexPage
